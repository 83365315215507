import Page from "../components/Page";
import homeMd from "../contents/home.md";
import React from "react";
import Wall from "../components/Wall";
import styles from './Home.module.css';

export interface HomeProps {
    contentId: string
}

export default function Home(props: HomeProps) {
    return <div className={styles.Home}>
        <Page content={homeMd} contentId={props.contentId}/>
        <Wall wallId={'WallHome'} images={
            [
                './img/wall_at_work.png',
                '/img/symbol-rune.gif'
            ]
        }/>   
    </div>;
}
