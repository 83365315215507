import React from "react";
import styles from "./Footer.module.css";
import {useLocation} from "react-router-dom";

export default function Footer() {
    const location = useLocation();
    function getFooter(pathname: string): JSX.Element {

        switch (pathname) {
            case '/':
                return <a href='mailto:nico.dreher@gmail.com'>Email me</a>
            case '/centrifuge-app':
                return <a href={'/fabric-design-system'}>Fabric Design System</a>
            case '/fabric-design-system':
                return <a href={'/centrifuge-app'}> Centrifuge App</a>
            case '/about':
                return <a href={'/projects'}>Works</a>
            case '/one-click-deployment':
                return <a href={'/appway-design-system'}>Appway Design System</a>
            case '/appway-design-system':
                return <a href={'/fabric-design-system'}>Fabric Design System</a>
            default:
                return <a href={'/'}>Home</a>    
        }
    }

    const children = getFooter(location.pathname)
    return <div className={styles.Footer}>{children}</div>;
}
