import styles from './Wall.module.css';
import {useMemo} from "react";

interface WallProps {
    images: string[]
    wallId: string
}

export default function Wall(props: WallProps) {
    const images = useMemo(() => {
        return props.images.map(image => (
            <div key={image} className={styles.WallImageWrapper} id={props.wallId}>
                <img className={styles.WallImage} src={image} alt={image}/>
            </div>
        ));
    }, [props.images, props.wallId]);
    return <div className={styles.Wall}>
        {images}
    </div>;
}
