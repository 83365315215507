import styles from './Header.module.css'

export default function HeaderHome() {

    return <div className={styles.Header}>
        <div className={styles.Blob}>
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1863 0C23.1442 0 32 7.25347 32 17.2113C32 26.6166 22.5916 32.247 13.1863 32.247C5.37633 32.247 0 25.0214 0 17.2113C0 8.74398 4.719 0 13.1863 0Z" fill="#3F00FF"/>
            </svg>
        </div>
    </div>
}