import React from 'react';
import Page from "./components/Page";
import about from './contents/about.md'
import centrifugeApp from './contents/centrifuge-app.md'
import fabricDesignSystem from './contents/fabric-design-system.md'
import oneClickDeployment from './contents/one-click-deployment.md'
import appwayDesignSystem from './contents/appway-design-system.md'
import backgroundCheck from './contents/background-check.md'
import projects from './contents/projects.md'
import {Route, Routes, useLocation} from "react-router-dom";
import styles from "./App.module.css"
import HeaderHome from "./components/HeaderHome";
import HeaderBack from "./components/HeaderBack";
import Footer from "./components/Footer";
import Home from "./pages/Home";

function App() {
    const location = useLocation();
    return (
        <div className={styles.App}>
            {location.pathname === '/' ? <HeaderHome/> : <HeaderBack/>}

            <Routes>
                <Route path="/" element={<Home contentId={'home'}/>}/>
                <Route path="/about" element={<Page content={about} contentId='about'/>}/>
                <Route path="/centrifuge-app" element={<Page content={centrifugeApp} contentId='centrifuge-app'/>}/>
                <Route path="/fabric-design-system" element={<Page content={fabricDesignSystem} contentId='fabric-design-system'/>}/>
                <Route path="/one-click-deployment" element={<Page content={oneClickDeployment} contentId='one-click-deployment'/>}/>
                <Route path="/appway-design-system" element={<Page content={appwayDesignSystem} contentId='appway-design-system'/>}/>
                <Route path="/background-check" element={<Page content={backgroundCheck} contentId='background-check'/>}/>
                <Route path="/projects" element={<Page content={projects} contentId='projects'/>}/>
            </Routes>
            <Footer/>
        </div>
    );  
}

export default App;
