import ReactMarkdown from "react-markdown";
import styles from "./Page.module.css"
import {useEffect, useState} from "react";

interface PageProps {
    content: string;
    contentId: string;
}

export default function Page(props: PageProps) {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(props.content)
            .then((res) => res.text())
            .then((text) => setMarkdown(text));
    }, [props.content]);
    useEffect(() => {
        const page = document.getElementsByClassName(styles.Page)[0];
        const images = page.getElementsByTagName("img");
        for (const image of images) {
            const parent = image.parentElement; // p element wrapping the image
            if (parent) { // parent is not null
                const caption = parent.querySelector("div"); // div in parent of image
                if (caption === null) {
                    parent.className = styles.ImageWrapper;
                    const e = document.createElement("div"); // create div
                    if (image.getAttribute("alt")) {
                        e.textContent = image.getAttribute("alt"); // add text to div
                        e.className = styles.Caption; // add class Caption to div
                        parent.appendChild(e); // add div to DOM
                    }
                }
            }
        }
    }, [markdown, props.contentId])
    return <div className={styles.PageWrapper} id={styles[props.contentId]}>
        <ReactMarkdown className={styles.Page} children={markdown}/>
    </div>
}
